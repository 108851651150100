import axios from '@/jslib/axios'

export const getTransactionList = params => {
  return axios({
    method: 'get',
    url: '/admin/transaction/records',
    params
  })
}
export const getTransactionReports = params => {
  return axios({
    method: 'get',
    url: '/admin/transaction/reports',
    params
  })
}
export const getTransactionReportsExport = params => {
  return axios({
    method: 'get',
    url: '/admin/transaction/records/export',
    responseType: 'blob',
    params
  })
}
